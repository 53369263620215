.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}
.paper {
  padding: 10px 0px;
}
.loadingDC span {
  margin-right: 10px;
}
.agenda-style > td {
  background-color: #ffffff !important;
}
.rbc-month-header,
.rbc-time-header-cell {
  min-height: 40px !important;
}
.rbc-time-header-cell div,
.rbc-month-header div {
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rbc-time-gutter {
  min-width: 71.125px;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.react-calendar {
  border-radius: 4px !important;
  width: 350px !important;
  max-width: 100% !important;
  background: white !important;
  font: inherit !important;
  line-height: 1.125em !important;
  border: 0 !important;
  overflow: hidden !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.layout-height {
  height: calc(100% - 24px);
}

@media only screen and (max-width: 600px) {
  .layout-height {
    height: unset;
  }
}
